

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { FormControlOptionModel, FormControlType } from '@common-src/model/form-control';
import { SpaceType, ViewModeType } from '@common-src/model/enum';
import { ICRUDQ } from '@common-src/model/interface';
import LabelService from '@common-src/service/label';
import SpaceService from '@common-src/service/space-v2';

@Component
export default class SpaceTreeAddLabelComponent extends FormDialogComponent<any> {
        treeItem: any;
        parentId: string;
        spaceType: SpaceType;

        // onChange(formControl: FormControlOptionModel) {
        //     if (formControl.key === 'serialNum' && this.treeItem) {
        //         this.jtlForm.formModel.code = (this.treeItem.parentCode || '') + (this.jtlForm.formModel.serialNum || '');
        //     }
        // }

        spaceTreedialogOpen(parentId: string, treeItem: any, spaceType: SpaceType, viewMode:ViewModeType):void {
            this.treeItem = treeItem;
            this.parentId = parentId;
            this.spaceType = spaceType;
            this.dialogVisible = true;
            this.viewMode = viewMode;
            let tags = [];
            let tagIds = '';
            if (!_.isEmpty(treeItem?.tagDtos) && !_.isEmpty(treeItem?.tagIds)) {
                tags = _.map(_.filter(treeItem.tagDtos, item => _.includes(treeItem.tagIds, item.id)), tagItem => tagItem.name);
            }
            if (!_.isEmpty(tags)) {
                tagIds = _.get(treeItem, 'tagIds');
            }
            this.$nextTick(() => {
                if (this.jtlForm) {
                    const controlItems = [
                        {
                            label: '名称',
                            key: 'name',
                            type: FormControlType.TEXT,
                            required: true,
                            value: _.get(treeItem, 'title')
                        },
                        {
                            label: '代码',
                            key: 'serialNum',
                            type: FormControlType.TEXT,
                            required: true,
                            value: _.get(treeItem, 'serialNum')
                        },
                        {
                            label: '编码',
                            key: 'code',
                            type: FormControlType.TEXT,
                            // readonly: true,
                            addHidden: true,
                            value: _.get(treeItem, 'code')
                        },
                        {
                            key: 'tagIds',
                            label: '标签',
                            type: FormControlType.SELECT,
                            optionsPromise: LabelService.getAllLabel.bind(LabelService),
                            value: tagIds || undefined,
                            // mode: 'multiple',
                            required: false
                        },
                        {
                            label: '排序',
                            key: 'sort',
                            type: FormControlType.NUMBER,
                            value: _.get(treeItem, 'sort'),
                            message: '按从大到小顺序排列: 3->2->1->0...',
                            noMessageTooltip: false
                        }
                    ];
                    this.jtlForm.initFormByControls(controlItems, this.viewMode);
                }
            });
        }

        dialogOK(): Promise<any> {
            if (!this.jtlForm) {
                return;
            }
            return this.jtlForm.submitForm().then((ret) => {
                const params: any = {
                    parentId: this.parentId || undefined,
                    type: this.spaceType,
                    id: _.get(this.treeItem, 'id'),
                    name: this.jtlForm.formModel.name,
                    tagIds: _.flatten([this.jtlForm.formModel.tagIds]),
                    serialNum: this.jtlForm.formModel.serialNum,
                    code: this.jtlForm.formModel.code,
                    sort: this.jtlForm.formModel.sort
                };
                if (this.viewMode === ViewModeType.NEW) {
                    SpaceService.create(params).then(res => {
                        this.dialogClose();
                        this.$emit('dialogOK');
                    });
                } else if (this.viewMode === ViewModeType.UPDATE) {
                    SpaceService.update(params).then(res => {
                        this.dialogClose();
                        this.$emit('dialogOK');
                    });
                }
            }).catch(err => {
                throw err;
            });
        }
}
